import { jsxs, jsx } from 'react/jsx-runtime';
import { Highlight20Regular } from '@fluentui/react-icons';
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Edit, Sort, Filter, Resize } from '@syncfusion/ej2-react-treegrid';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { palette } from '../../themes/light.theme.js';
import '@mui/material';
import { Chip } from '../chip/Chip.js';
import '../../types/requestTypes.js';
import '../../types/itemTypes.js';
import '../chip/ItemManagementStatusChip.js';
import '../chip/PerspectiveClassChip.js';
import { Typography } from '../Typography.js';
import { getParentNameFromObject } from '../item/ItemPropertiesUtil.js';
import { PropertyValueTemplate, dateTimeRegex } from '../item/template/PropertyValueTemplate.js';
import { transformSyncFusionPropertiesToJson } from '../item/ItemBusinessTypeUtil.js';
import '../../services/requestApi.js';
import '../../services/userApi.js';
import { useSaveItemDetailsMutation } from '../../services/itemApi.js';
import '../../services/requestDetailsApi.js';
import '../../auth/config.js';
import '../../config.js';
import '../../auth/AuthProvider.js';
import '@azure/msal-react';
import { formatDate, areDatesEqual } from '../../util/dateTime.js';
import 'react-redux';
import '@testing-library/react';
import '../../store/store.js';
import 'redux-persist/integration/react';
import '../../store/slices/applicationSlice.js';
import '../../store/slices/downloadFileSlice.js';
import '../../store/slices/itemModalSlice.js';
import { Field } from '../../hooks/useItemPropertiesPanel.js';
import { isDate, isEnum, isDouble, isBoolean, isInteger, isDateTime, isEntity } from '../item/utils.js';
import { DatePicker, DateTimePicker } from '@syncfusion/ej2-react-calendars';
import { getEnumValues, getPerspectiveClassValues } from '../dialog/components/item/customTypeUtils.js';
import { DropDownList, AutoComplete } from '@syncfusion/ej2-react-dropdowns';
import { NumericTextBox } from '@syncfusion/ej2-react-inputs';

const ItemPropertiesGrid = ({ item, gridRef, menuItem, formDetails, editOptions, itemTypeName, enums, perspectiveClasses: _perspectiveClasses, hasEditPermission, handleRowSelection, handleRowSelecting, handleRecordDoubleClick, }) => {
    const hoverAttributes = { class: 'editable-column' };
    const [saveItemDetails] = useSaveItemDetailsMutation();
    let elem;
    let textField;
    let enumField;
    let doubleField;
    let booleanField;
    let integerField;
    let datePickerField;
    let dateTimePickerField;
    let perspectiveClassField;
    const editTemplate = {
        create: () => {
            elem = document.createElement('input');
            return elem;
        },
        read: (args) => {
            return args?.value;
        },
        write: (args) => {
            const { rowData } = args;
            const { propDef } = rowData;
            const isDateType = isDate(propDef);
            const isEnumType = isEnum(propDef);
            const isDoubleType = isDouble(propDef);
            const isBooleanType = isBoolean(propDef);
            const isIntegerType = isInteger(propDef);
            const isDateTimeType = isDateTime(propDef);
            const isPerspectiveClassType = isEntity(propDef) &&
                propDef?.PropTypeDef?.Name?.indexOf('IT_') === 0 &&
                propDef?.PropTypeDef?.Category?.InternalName === 'Inheritance';
            if (isDateType) {
                const value = args.rowData?.value;
                datePickerField = new DatePicker({
                    value: value ? new Date(formatDate(value, false)) : null,
                    format: 'MM/dd/yyyy',
                    showTodayButton: false,
                    showClearButton: false,
                    cssClass: 'custom-type-datepicker',
                    allowEdit: false,
                });
                datePickerField.appendTo(elem);
            }
            else if (isDateTimeType) {
                const value = args.rowData[args.column.field];
                dateTimePickerField = new DateTimePicker({
                    value: value ? new Date(formatDate(value)) : null,
                    format: 'MM/dd/yyyyT00:00:00',
                    showTodayButton: false,
                    showClearButton: false,
                    cssClass: 'custom-type-datepicker',
                    allowEdit: false,
                });
                dateTimePickerField.appendTo(elem);
            }
            else if (isEnumType) {
                const value = args.rowData[args.column.field];
                const { PropTypeDefId } = propDef;
                const enumValues = getEnumValues(enums, PropTypeDefId);
                const sortOrder = 'Ascending';
                enumField = new DropDownList({
                    value,
                    fields: { text: 'Caption', value: 'name' },
                    dataSource: enumValues,
                    cssClass: 'custom-type-dropdown',
                    sortOrder,
                    popupHeight: '245px',
                });
                enumField.appendTo(elem);
            }
            else if (isIntegerType) {
                const value = args.rowData[args.column.field];
                integerField = new NumericTextBox({
                    value,
                    format: '####',
                    cssClass: 'custom-type-numeric',
                });
                integerField.appendTo(elem);
            }
            else if (isDoubleType) {
                const value = args.rowData[args.column.field];
                doubleField = new NumericTextBox({
                    value,
                    format: '####.00',
                    decimals: 2,
                    cssClass: 'custom-type-numeric',
                });
                doubleField.appendTo(elem);
            }
            else if (isBooleanType) {
                const value = args.rowData.value;
                const mapping = {
                    "null": "",
                    "true": "Yes",
                    "false": "No",
                };
                Field.Boolean;
                booleanField = new DropDownList({
                    value: mapping[value?.toString()],
                    fields: { text: 'label', value: 'value' },
                    dataSource: [
                        { label: '', value: "" },
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                    ],
                    cssClass: 'custom-type-dropdown',
                });
                booleanField.appendTo(elem);
            }
            else if (isPerspectiveClassType) {
                let value = args.rowData[args.column.field];
                Field.PerspectiveClass;
                const propTypeDefId = propDef?.PropTypeDefId;
                const perspectiveClasses = getPerspectiveClassValues(_perspectiveClasses, propTypeDefId, '');
                const perspectiveClassValue = perspectiveClasses?.find((x) => x?._Display === value?._Display);
                perspectiveClassField = new DropDownList({
                    value: perspectiveClassValue?.ID,
                    dataSource: perspectiveClasses,
                    fields: {
                        text: '_Display',
                        value: 'ID',
                    },
                    cssClass: 'custom-type-dropdown',
                });
                perspectiveClassField.appendTo(elem);
            }
            else {
                // TODO: update the component to TextBox, currently, there's an issue on inline editing.
                const value = args.rowData[args.column.field];
                textField = new AutoComplete({
                    value,
                    showClearButton: false,
                    zIndex: -1000,
                });
                textField.appendTo(elem);
            }
        },
    };
    const handleActionComplete = async (args) => {
        if (args.type !== 'save')
            return;
        // Just a workaround to show the template values after inline edit
        gridRef.current.refreshColumns();
        // Check if the edited value matches the previous value
        const { previousData, data } = args;
        const newValue = data?.value;
        const isValueUnchanged = previousData === newValue ||
            areDatesEqual(previousData, newValue) ||
            (dateTimeRegex.test(previousData) &&
                previousData.substring(0, 10) === newValue);
        if (isValueUnchanged)
            return;
        const businessObject = formDetails?.BusinessObject;
        const parentName = getParentNameFromObject(data);
        const storedGridData = JSON.parse(localStorage.getItem('treeGridResultProperties') || '[]');
        // Get updated tree grid data
        const getUpdatedTreeGridData = () => {
            const cellValue = gridRef.current?.dataSource.find((x) => x.propDef.Name === parentName);
            return storedGridData.map((x) => x.propDef.Name === parentName ? cellValue ?? null : x);
        };
        const updatedTreeGridData = getUpdatedTreeGridData();
        // Get business type grid data
        const businessTypeGridData = Object.keys(businessObject)
            .map((key) => updatedTreeGridData.find((x) => x.propDef.Name === key))
            .filter(Boolean);
        const treeGridDataJSON = transformSyncFusionPropertiesToJson(businessTypeGridData, businessObject);
        // Format formData and requestObj
        const formData = {
            BusinessObject: { ...treeGridDataJSON },
            RepositoryId: item?.RepositoryId,
        };
        const requestObj = {
            ID: item?.ID,
            TypeDefId: item?.TypeDefId,
            formDetails: formData,
        };
        try {
            // Show spinner and save values
            gridRef.current?.showSpinner();
            setTimeout(async () => {
                await saveItemDetails(requestObj);
                gridRef.current?.hideSpinner();
            }, 1000);
        }
        catch (error) {
            console.error('Error on updating the item details on the properties page:', error);
            gridRef.current?.hideSpinner();
        }
    };
    return (jsxs(TreeGridComponent, { ref: gridRef, treeColumnIndex: 0, allowSorting: true, width: '100%', enablePersistence: true, childMapping: 'Items', id: 'item-details-properties-grid', className: 'item-details-properties-grid', loadingIndicator: { indicatorType: 'Shimmer' }, allowResizing: true, editSettings: editOptions, rowSelected: handleRowSelection, rowSelecting: handleRowSelecting, actionComplete: handleActionComplete, recordDoubleClick: handleRecordDoubleClick, children: [jsxs(ColumnsDirective, { children: [jsx(ColumnDirective, { allowEditing: false, field: 'propertyName', headerText: 'PROPERTY NAME', width: '18.5rem', template: (item) => {
                            const propertyName = item?.propertyName;
                            return (jsx(Typography, { variant: 'body1', sx: {
                                    color: palette.navy[900],
                                    WebkitLineClamp: 3,
                                }, children: propertyName }));
                        } }), jsx(ColumnDirective, { allowEditing: true, field: 'value', headerText: 'VALUE', width: 'auto', type: 'string', edit: editTemplate, template: (item) => {
                            const parentName = getParentNameFromObject(item);
                            const _isAllowEditing = !item?.isCollection &&
                                !item?.childRecords?.length &&
                                (parentName in formDetails?.BusinessObject ||
                                    [
                                        'Business Type',
                                        'Perspective Classes',
                                        'Retention Classes',
                                    ].includes(item?.propertyName));
                            return (jsx(PropertyValueTemplate, { item: item.value, isEditable: _isAllowEditing }));
                        }, customAttributes: hasEditPermission ? hoverAttributes : null }), menuItem !== itemTypeName && (jsx(ColumnDirective, { allowEditing: false, field: 'highlights', headerText: 'HIGHLIGHTS', width: 'auto', template: (item) => item.highlights ? (jsx(Chip, { border: false, title: item.highlights, icon: jsx(Highlight20Regular, {}), color: 'success', sx: {
                                backgroundColor: palette.aqua[50],
                                color: palette.aqua[900],
                            } })) : null }))] }), jsx(Inject, { services: [Edit, Sort, Filter, Resize] })] }));
};
const MemoizedItemPropertiesGrid = memo(ItemPropertiesGrid, (prevState, nextState) => prevState.menuItem === nextState.menuItem &&
    isEqual(prevState.formDetails, nextState.formDetails) &&
    isEqual(prevState.enums, nextState.enums) &&
    isEqual(prevState.perspectiveClasses, nextState.perspectiveClasses) &&
    prevState.item?.ID === nextState.item?.ID);

export { ItemPropertiesGrid, MemoizedItemPropertiesGrid };
