import { jsx, jsxs } from 'react/jsx-runtime';
import { styled } from '@mui/material';
import { Box } from './Box.js';
import { InfoPanel } from './InfoPanel.js';
import { DetailsRow } from './DetailsRow.js';
import { Chip } from './chip/Chip.js';
import '@fluentui/react-icons';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import 'react';
import '../themes/light.theme.js';
import './chip/ItemManagementStatusChip.js';
import './chip/PerspectiveClassChip.js';
import '../config.js';
import '../auth/config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import { formatDestructionDate } from '../util/dateTime.js';
import 'react-redux';
import '@testing-library/react';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../constants/messages.js';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import { useIcon } from '../hooks/useIcon.js';
import './item/utils.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';

const StyledImage = styled('img') ``;
const SnapshotDetailsPanel = ({ snapshot, itemDetails, businessTypeIconDetails, handleSnapshotChipOpen, snapshotMembers }) => {
    const snapshotItem = snapshotMembers?.find((x) => x.ID === snapshot?.RelatedObjId);
    const itemImageId = snapshotItem?.BusinessType?._ImageId ?? snapshotItem?._ImageId ?? snapshotItem?.TypeDef?._ImageId;
    const { iconDetails } = useIcon({
        imageId: itemImageId,
    });
    const renderAdditionalInformation = snapshot?.HistoryType !== 'Membership'
        ? snapshot?.Description
        : (jsx(Chip, { sx: { width: 'auto', maxWidth: '100%' }, title: snapshotItem?.DisplayName, border: false, icon: iconDetails ? (jsx(StyledImage, { style: {
                    width: '1.5rem',
                    height: '1.5rem',
                }, alt: iconDetails?.AltText, src: `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}` })) : null }));
    return (jsx(Box, { padding: 'none', background: 'none', direction: 'row', gap: 'none', height: 'fit-content', children: jsx(Box, { background: 'none', width: '100%', style: { overflow: 'overlay', paddingRight: '1rem', overflowY: 'auto' }, children: jsxs(InfoPanel, { inset: '3.5rem', title: 'Snapshot Properties', style: { paddingRight: '5.25rem', paddingTop: '0.988rem' }, children: [jsx(DetailsRow, { label: 'Snapshot of', data: jsx(Chip, { sx: { width: 'auto' }, title: itemDetails?.DisplayName, icon: businessTypeIconDetails ? (jsx("img", { style: {
                                    width: '1.5rem',
                                    height: '1.5rem',
                                }, alt: businessTypeIconDetails?.AltText, src: `${process.env.REACT_APP_ENC_IMC_URL}${businessTypeIconDetails?.Url}` })) : null, onClick: handleSnapshotChipOpen }) }, 'snapshot-chip'), jsx(DetailsRow, { label: 'Date Captured', data: formatDestructionDate(snapshot?.DateCaptured) }, 'date-captured'), jsx(DetailsRow, { label: 'Change', data: snapshot?.HistoryType === 'Membership' ? snapshot?.Description : snapshot?.HistoryType }, 'snapshot-change'), jsx(DetailsRow, { label: 'Additional Information', data: renderAdditionalInformation }, 'additional-information')] }) }) }));
};

export { SnapshotDetailsPanel };
