import { jsx, jsxs } from 'react/jsx-runtime';
import { styled, useTheme, Button, Divider } from '@mui/material';
import { memo, useState, useCallback, useEffect, useMemo } from 'react';
import { config } from '../config.js';
import '../types/requestTypes.js';
import { ItemManagementStatus, ItemGridQuery } from '../types/itemTypes.js';
import { insertIf } from '../util/array-utils.js';
import '../auth/config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import { lightTheme } from '../themes/light.theme.js';
import { useAppDispatch, useAppSelector } from '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import { setSelectedSnapshot, updateItem, setCurrentItem, setSnapshotMembers } from '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../constants/messages.js';
import '../services/requestApi.js';
import '../services/userApi.js';
import { useGetSnapshotCountQuery, useLazyGetMostRecentSnapshotQuery, useLazyGetSnapshotMemberCountQuery, useLazyGetMultipleItemDetailsQuery, itemApi } from '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import { useUser, useIMCUser } from '../hooks/useUser.js';
import 'lodash';
import { getQueryProps } from './item/utils.js';
import { useItemPanel } from '../hooks/useItemPanel.js';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import { useDownloadFile } from '../hooks/useDownloadFile.js';
import { Box } from './Box.js';
import { MemoizedFileViewer } from './FileViewer.js';
import { MemoizedItemPropertiesPanel } from './ItemPropertiesPanel.js';
import { ItemSummaryPanel } from './ItemSummaryPanel.js';
import { ItemSummaryTitle } from './ItemSummaryTitle.js';
import { TabPanel } from './TabPanel.js';
import { Alert } from './alert/Alert.js';
import { Info48Filled, ColumnEdit20Regular } from '@fluentui/react-icons';
import { DisposalDetailsPanel } from './DisposalDetailsPanel.js';
import './grid/components/PageSizeDropdown.js';
import './grid/components/PageSelector.js';
import '@mui/system';
import '@syncfusion/ej2-data';
import '@syncfusion/ej2-react-grids';
import './chip/ItemManagementStatusChip.js';
import './chip/PerspectiveClassChip.js';
import { Typography } from './Typography.js';
import './grid/templates/CommentTemplate.js';
import './grid/templates/ItemSummaryTemplate.js';
import './grid/templates/PagerTemplate.js';
import './grid/templates/RetentionClassTemplate.js';
import './grid/templates/SummaryTemplate.js';
import './grid/templates/DisposalStatusTemplate.js';
import { ItemsGrid } from './grid/ItemsGrid.js';
import { SnapshotsGrid } from './grid/SnapshotsGrid.js';
import { SnapshotDetailsPanel } from './SnapshotDetailsPanel.js';
import { dateTimeToUTC } from '../util/date-time-to-utc.js';

const StyledImage = styled('img') ``;
/**
 * ItemPanel component to display details of an item
 * @param id - id of the Item
 * @param tab - selected tab ('summary' etc.,)
 * @event onSelectTab - select tab event
 * @returns ItemPanel JSX.Element
 */
const ItemPanel = memo(({ initialId = '', id, initialTab, onSelectTab, adminMode, disposalRequest, setId, clearLevels = false }) => {
    // -----------------------------
    // Theme and utility hooks
    // -----------------------------
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const selectedSnapshot = useAppSelector((state) => state.itemModal.selectedSnapshot);
    const levels = useAppSelector((state) => state.itemModal.navigation.levels);
    const snapshotMembers = useAppSelector((state) => state.itemModal.snapshotMembers);
    const currId = levels?.[levels?.length - 1]?.itemId ?? id;
    const snapshotGridKey = `${currId}-${levels?.[levels?.length - 1]?.index ?? 0}`;
    const [currentSnapshotGridPage, setCurrentSnapshotGridPage] = useState(1);
    const [currentSnapshotGridPageSize, setCurrentSnapshotGridPageSize] = useState(30);
    const { data: snapshotData, isSuccess } = useGetSnapshotCountQuery({ itemId: id, currentPage: currentSnapshotGridPage, pageSize: currentSnapshotGridPageSize });
    const [getRecentSnapshot] = useLazyGetMostRecentSnapshotQuery();
    const [getSnapshotMemberCount, { data: snapshotMemberCount }] = useLazyGetSnapshotMemberCountQuery();
    const [getSnapshotItems, { data: snapshotItems }] = useLazyGetMultipleItemDetailsQuery();
    const { iconDetails, itemDetails, selectedTab, formDetails, handleSelectTab, propertyDetails, treeGridProperties, businessTypeProperties, businessTypeIconDetails, businessTypeTreeGridData, businessTypePropertyValues, itemCollectionCount, showColumnChooser, handleCheckbox, handleOpen, handleOpenURL, gridRef, selectedCheckbox, fullScreenTab, handleOpenItems, hasPreviewableContent, setPreviewState, handleChangeTab, enums, perspectiveClasses, isItemCollection, hasError } = useItemPanel({ id: currId, initialTab, onSelectTab, disposalRequest, setId, levels });
    const [selectedRowHighlight, setSelectedRowHighlight] = useState([]);
    const [showSnapshotDetails, setShowSnapshotDetails] = useState(false);
    const [alertError, setAlertOpen] = useState(false);
    const [alertSuccess, setSuccessOpen] = useState(false);
    const stringToCopy = `${config.REACT_APP_ENC_LANDING_APP_URL}items/${itemDetails?.ID}`;
    const copyErrorMessage = 'An error occurred while copying this link to clipboard';
    const copySuccessMessage = 'Link to this item has been copied to clipboard';
    const { downloadFile } = useDownloadFile();
    const user = useUser();
    const queryPropsResult = getQueryProps();
    const imcUser = queryPropsResult ? useIMCUser(queryPropsResult) : null;
    let canIMCUSerEdit = false;
    if (imcUser) {
        canIMCUSerEdit = imcUser.UserAccessType === 'Full' ? true : false;
    }
    const userPermission = user?.UserAccessType === 'Full' ?? false;
    const hasEditPermission = canIMCUSerEdit ||
        (adminMode &&
            userPermission &&
            itemDetails?.Status !== ItemManagementStatus.Destroyed);
    const rowSelected = useCallback((rowData) => {
        if (rowData?.data?.highlightData?.length > 0) {
            setSelectedRowHighlight(rowData.data.highlightData);
        }
    }, []);
    useEffect(() => {
        if (levels?.length) {
            if (!levels?.[levels?.length - 1]?.isSnapshot) {
                setShowSnapshotDetails(false);
                if (levels?.[levels?.length - 1]?.selectedSnapshot)
                    handleChangeTab('snapshotHistory');
                else if (levels[levels.length - 1]?.initialTab === 'items' || levels[levels?.length - 1]?.pageIndex)
                    handleChangeTab('items');
                else
                    handleChangeTab('summary');
            }
            else {
                if (levels?.length >= 2 && levels?.[levels?.length - 2]?.selectedSnapshot?.startDate) {
                    const startDate = levels?.[levels?.length - 2]?.selectedSnapshot?.startDate;
                    const endDate = levels?.[levels?.length - 2]?.selectedSnapshot?.endDate;
                    getSnapshotMemberCount({
                        itemId: currId,
                        startDate,
                        endDate,
                    });
                }
                setShowSnapshotDetails(true);
                if (levels?.[levels?.length - 1]?.openSnapshotMembers && snapshotData?.['@odata.count'])
                    handleChangeTab('snapshotItems');
                else
                    handleChangeTab('snapshotDetails');
            }
        }
    }, [levels?.length]);
    useEffect(() => {
        if (selectedSnapshot && selectedTab !== 'snapshotHistory' && !showSnapshotDetails) {
            dispatch(setSelectedSnapshot(undefined));
            dispatch(updateItem({ ...levels?.[levels?.length - 1], selectedSnapshot: undefined }));
        }
    }, [selectedTab]);
    // Makes sure that every time an item is opened in IMC, the tabs and items are reset
    useEffect(() => {
        if (clearLevels) {
            setShowSnapshotDetails(false);
            handleChangeTab('summary');
            dispatch(setCurrentItem(undefined));
            dispatch(setSelectedSnapshot(undefined));
        }
    }, [initialId]);
    // Sets the initial level value in IMC
    useEffect(() => {
        if (!levels?.length && clearLevels && itemDetails?.ID) {
            dispatch(setCurrentItem({ id: itemDetails.ID, itemId: itemDetails.ID, name: itemDetails.DisplayName }));
        }
    }, [itemDetails]);
    useEffect(() => {
        let itemIds = [];
        snapshotData?.value?.map((snap) => itemIds.push(snap.RelatedObjId));
        if (itemIds?.length) {
            const fetchSnapshotMembersData = async () => {
                const { data } = await getSnapshotItems({ itemIds, size: currentSnapshotGridPageSize });
                return data;
            };
            fetchSnapshotMembersData();
        }
    }, [snapshotData]);
    useEffect(() => {
        if (snapshotItems?.value) {
            dispatch(setSnapshotMembers(snapshotItems?.value));
        }
    }, [snapshotItems]);
    const handleCopyItemLink = () => {
        navigator.clipboard
            .writeText(stringToCopy)
            .then(() => {
            setSuccessOpen(true);
        })
            .catch((err) => {
            setAlertOpen(true);
        });
    };
    const handleSnapshotSelect = async (snapshot) => {
        if (!showSnapshotDetails) {
            if (!snapshot?.[0]?._Display) {
                dispatch(setSelectedSnapshot(undefined));
                dispatch(updateItem({ ...levels?.[levels?.length - 1], selectedSnapshot: undefined }));
            }
            else {
                if (selectedSnapshot?.selectedIndex === snapshot[0]?.selectedIndex)
                    return;
                itemApi.util.invalidateTags(['RecentSnapshot']);
                const startDate = snapshot[0]?.DateCaptured?.toISOString().split('.')[0];
                const { data } = await getRecentSnapshot({ itemId: currId, startDate, nextVersion: true });
                let isCorrectRow = false;
                let baseSnapshotData = [];
                // Need to get correct data since data value may have same startDate
                data?.snapshot?.map((snap) => {
                    if (snap?.RelatedObjId === snapshot[0]?.RelatedObjId && snap?._Display === snapshot[0]?._Display)
                        isCorrectRow = true;
                    if (isCorrectRow)
                        baseSnapshotData.push(snap);
                });
                const officialStartDate = baseSnapshotData?.[0]?.DateCaptured?.split('+')[0];
                const endDate = baseSnapshotData?.length > 1 ? baseSnapshotData?.[1]?.DateCaptured.split('+')[0] : officialStartDate;
                const snapshotRow = {
                    ...snapshot[0],
                    startDate: officialStartDate,
                    ...endDate && {
                        endDate
                    }
                };
                if (data?.snapshot) {
                    dispatch(setSelectedSnapshot(snapshotRow));
                    if (levels?.length)
                        dispatch(updateItem({ ...levels?.[levels?.length - 1], selectedSnapshot: snapshotRow }));
                }
            }
        }
    };
    const handleItemSelect = async (item) => {
        const { data } = await getRecentSnapshot({ itemId: item?.ID, startDate: levels?.[levels?.length - 2]?.selectedSnapshot?.startDate, nextVersion: false });
        if (!data?.snapshot)
            handleOpen(item);
        else {
            const snapshotValue = {
                ...data?.snapshot?.[0],
                startDate: data?.snapshot[0]?.DateCaptured,
                selectedIndex: item?.index,
                selectedPageIndex: item?.page
            };
            dispatch(updateItem({
                ...levels?.[levels?.length - 1],
                selectedSnapshot: snapshotValue,
                openSnapshotMembers: true,
                isItemCollection: true,
                pageIndex: item?.page,
                pageSize: item?.pageSize,
                rowIndex: item?.index,
                sortSettings: item?.sortSettings,
            }));
            dispatch(setCurrentItem({
                id: snapshotValue.RelatedObjId,
                itemId: item?.ID,
                name: dateTimeToUTC(snapshotValue.DateCaptured),
                isSnapshot: true,
                openSnapshotMembers: false,
                isItemCollection: item?.Members?.['@odata.count'] > 0,
                prevPageIndex: item?.page,
                prevPageSize: item?.pageSize,
                prevRowIndex: item?.index,
                prevSortSettings: item?.sortSettings,
            }));
        }
    };
    const handleItemMemberSelect = async (item) => {
        const { data } = await getRecentSnapshot({ itemId: item?.ID, startDate: levels?.[levels?.length - 2]?.selectedSnapshot?.startDate, nextVersion: false });
        if (!data?.snapshot)
            handleOpenItems(item);
        else {
            const snapshotValue = {
                ...data?.snapshot?.[0],
                startDate: data?.snapshot[0]?.DateCaptured,
                selectedIndex: item?.index,
                selectedPageIndex: item?.page
            };
            dispatch(updateItem({
                ...levels?.[levels?.length - 1],
                selectedSnapshot: snapshotValue,
                openSnapshotMembers: true,
                isItemCollection: true,
                pageIndex: item?.page,
                pageSize: item?.pageSize,
                rowIndex: item?.index,
                sortSettings: item?.sortSettings,
            }));
            dispatch(setCurrentItem({
                id: snapshotValue.RelatedObjId,
                itemId: item?.ID,
                name: snapshotValue._Display,
                isSnapshot: true,
                openSnapshotMembers: true,
                isItemCollection: item?.Members?.['@odata.count'] > 0,
                prevPageIndex: item?.page,
                prevPageSize: item?.pageSize,
                prevRowIndex: item?.index,
                prevSortSettings: item?.sortSettings,
            }));
        }
    };
    const handleOpenSnapshot = () => {
        setShowSnapshotDetails(true);
        dispatch(updateItem({ ...levels?.[levels?.length - 1], initialTab: 'snapshotHistory' }));
    };
    const handleSnapshotChipOpen = async () => {
        dispatch(updateItem({ ...levels?.[levels?.length - 1], openSnapshotMembers: false }));
        dispatch(setCurrentItem({ name: itemDetails?.DisplayName, id: itemDetails?.ID, itemId: itemDetails?.ID, shouldExpand: true }));
    };
    const handleSnapshotGridUpdate = ({ pageSize, currentPage }) => {
        setCurrentSnapshotGridPage(currentPage);
        setCurrentSnapshotGridPageSize(pageSize);
    };
    const getSelectedSnapshot = () => {
        if (levels?.[levels?.length - 1]?.isSnapshot)
            return levels?.[levels?.length - 2]?.selectedSnapshot;
        else
            return levels?.[levels?.length - 1]?.selectedSnapshot;
    };
    const MemoizedItemsGrid = useMemo(() => {
        return (jsx(ItemsGrid, { isItemPanel: true, ref: gridRef, adminMode: adminMode, onOpen: handleOpen, onOpenURL: handleOpenURL, onOpenItems: handleOpenItems, onSelect: handleCheckbox, disposalId: id, disposalRequest: disposalRequest, itemGridType: ItemGridQuery.ChildWithStatus, selectedRecords: selectedCheckbox, persistanceId: `panelGrid${JSON.stringify(levels).length}-4`, isItemCollection: true, levels: levels }));
    }, [id, levels]);
    const MemoizedMembersGrid = useMemo(() => {
        return (jsx(ItemsGrid, { isItemPanel: true, ref: gridRef, adminMode: adminMode, onOpen: handleItemSelect, onOpenURL: handleOpenURL, onOpenItems: handleItemMemberSelect, disposalId: currId, disposalRequest: disposalRequest, itemGridType: ItemGridQuery.SnapshotMembers, persistanceId: `snapshots-panelGrid${JSON.stringify(levels).length}-4`, isItemCollection: true, levels: levels, startDate: clearLevels ? selectedSnapshot?.startDate : levels?.[levels?.length - 2]?.selectedSnapshot?.startDate, endDate: clearLevels ? selectedSnapshot?.endDate : levels?.[levels?.length - 2]?.selectedSnapshot?.endDate }));
    }, [id, currId, levels, selectedSnapshot]);
    return hasError ? (jsx(Box, { width: '100%', height: '100%', direction: 'row', alignItems: 'center', justifyContent: 'center', style: {
            backgroundColor: lightTheme.palette.common.white,
            maxHeight: 'calc(100% - 3.375rem)',
        }, children: jsxs(Box, { direction: 'column', alignItems: 'center', background: 'light', style: {
                backgroundColor: lightTheme.palette.common.white,
                flex: 'unset',
                maxWidth: '25rem',
            }, children: [jsx(Info48Filled, { style: {
                        paddingBottom: '1.25rem',
                        color: lightTheme.palette.primary.main,
                    } }), jsx(Typography, { variant: 'h2', color: lightTheme.palette.text.primary, style: {
                        whiteSpace: 'normal',
                        overflow: 'visible',
                        textAlign: 'center',
                    }, WebkitLineClamp: 2, children: "You do not have permission to view this file. Please contact your admin to gain access" })] }) })) : (jsxs(Box, { background: 'none', children: [jsx(ItemSummaryTitle, { isMoreButtonAvailable: itemDetails?.HasEverCapturedFile, icon: iconDetails ? (jsx(StyledImage, { src: `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url}`, alt: iconDetails?.AltText, style: { height: '1.5rem' } })) : null, displayName: itemDetails?.DisplayName, itemType: itemDetails?.TypeDef?._Display, businessType: itemDetails?.BusinessType?._Display, onDownloadClick: () => {
                    let versionToDownload;
                    if (levels?.[levels?.length - 1]?.isSnapshot)
                        versionToDownload = levels?.[levels?.length - 1]?.id;
                    else if (selectedSnapshot)
                        versionToDownload = selectedSnapshot?.RelatedObjId;
                    else
                        versionToDownload = undefined;
                    downloadFile({
                        id,
                        version: versionToDownload,
                        itemDetails,
                    });
                }, onCopyLinkClick: () => {
                    handleCopyItemLink();
                } }), jsxs(Box, { direction: 'row', background: 'none', children: [jsx(Box, { background: 'none', width: !fullScreenTab ? '50%' : '100%', children: jsx(TabPanel, { selected: selectedTab, onSelect: handleSelectTab, inset: '3.5rem', tabs: [
                                {
                                    title: 'Summary',
                                    value: 'summary',
                                    isVisible: !showSnapshotDetails,
                                    children: (jsx(ItemSummaryPanel, { item: itemDetails, disposalRequest: disposalRequest, hasEditPermission: hasEditPermission, treeGridProperties: treeGridProperties, businessTypeProperties: businessTypeProperties, businessTypeIconDetails: businessTypeIconDetails, businessTypePropertyValues: businessTypePropertyValues, onOpen: handleOpen })),
                                },
                                {
                                    title: 'Details',
                                    value: 'snapshotDetails',
                                    isVisible: showSnapshotDetails,
                                    children: (jsx(SnapshotDetailsPanel, { snapshot: levels?.[levels?.length - 2]?.selectedSnapshot, itemDetails: itemDetails, businessTypeIconDetails: businessTypeIconDetails, handleSnapshotChipOpen: handleSnapshotChipOpen, snapshotMembers: snapshotMembers })),
                                },
                                {
                                    title: 'Properties',
                                    value: 'properties',
                                    children: (jsx(MemoizedItemPropertiesPanel, { selectedTab: selectedTab, formDetails: formDetails, propertyDetails: propertyDetails, hasEditPermission: hasEditPermission, businessTypeTreeGridData: businessTypeTreeGridData, rowSelected: rowSelected, itemTypeName: itemDetails?.TypeDef?._Display
                                            ? `${itemDetails?.TypeDef?._Display} Properties`
                                            : undefined, businessTypeName: itemDetails?.BusinessType?._Display
                                            ? `${itemDetails?.BusinessType?._Display} Properties`
                                            : undefined, enums: enums, perspectiveClasses: perspectiveClasses })),
                                },
                                {
                                    title: 'Disposal Details',
                                    value: 'disposalDetails',
                                    isVisible: !showSnapshotDetails,
                                    children: jsx(DisposalDetailsPanel, { itemId: itemDetails?.ID }),
                                },
                                ...insertIf(isItemCollection, {
                                    title: `${itemCollectionCount?.count} ${itemDetails.AttachedItems?.value?.length > 0
                                        ? 'Attachment'
                                        : 'Item'}${itemCollectionCount?.count != 1 ? `s` : ``}`,
                                    value: 'items',
                                    isVisible: !showSnapshotDetails,
                                    children: (jsxs(Box, { padding: 'large', background: 'none', style: {
                                            paddingTop: '0.6rem',
                                            height: '100%',
                                        }, children: [jsx(Box, { direction: 'row', alignItems: 'center', background: 'none', height: 5, children: jsx(Box, { background: 'none', gap: 'small', direction: 'row', justifyContent: 'end', children: jsx(Button, { variant: 'text', startIcon: jsx(ColumnEdit20Regular, {}), onClick: showColumnChooser, children: "Change Columns" }) }) }), jsx(Box, { background: 'none', className: 'item-panel-items-grid', style: {
                                                    paddingTop: '0.6rem',
                                                    height: '100%',
                                                }, children: MemoizedItemsGrid })] })),
                                }),
                                ...insertIf(snapshotMemberCount?.['@odata.count'] > 0 && ((levels?.length > 1 && levels[levels?.length - 2]?.selectedSnapshot?.HistoryType === 'Membership') || clearLevels), {
                                    title: `${snapshotMemberCount?.['@odata.count']} Member Snapshots`,
                                    value: 'snapshotItems',
                                    isVisible: showSnapshotDetails && snapshotMemberCount?.['@odata.count'] > 0,
                                    children: (jsx(Box, { padding: 'large', background: 'none', style: {
                                            paddingTop: '0.6rem',
                                            height: '100%',
                                        }, children: jsx(Box, { background: 'none', className: 'item-panel-members-grid', style: {
                                                paddingTop: '0.6rem',
                                                height: '100%',
                                            }, children: MemoizedMembersGrid }) })),
                                }),
                                {
                                    title: 'Snapshot History',
                                    value: 'snapshotHistory',
                                    isVisible: !showSnapshotDetails,
                                    disabled: !isSuccess || !snapshotData?.['@odata.count'],
                                    children: (jsx(Box, { padding: 'large', background: 'none', style: {
                                            paddingTop: '2rem',
                                            height: '100%',
                                        }, children: jsx(Box, { background: 'none', className: 'item-panel-items-grid', style: {
                                                paddingTop: '0.6rem',
                                                height: '100%',
                                            }, children: jsx(SnapshotsGrid, { id: currId, onSelect: handleSnapshotSelect, selectedSnapshot: clearLevels ? selectedSnapshot : levels?.[levels?.length - 1]?.selectedSnapshot, snapshotMembers: snapshotMembers, onDataChange: handleSnapshotGridUpdate }, snapshotGridKey) }) })),
                                }
                            ] }) }), !fullScreenTab && (jsxs(Box, { width: '50%', height: 'calc(100% - 2rem)', alignItems: 'center', justifyContent: 'start', style: {
                            backgroundColor: theme.palette.info[100],
                            marginTop: '2rem',
                        }, children: [hasPreviewableContent && jsx(Divider, { style: { width: '100%' } }), jsx(MemoizedFileViewer, { id: currId, itemDetails: itemDetails, rowData: selectedRowHighlight, setPreviewState: setPreviewState, selectedSnapshot: getSelectedSnapshot(), onSnapshotOpen: handleOpenSnapshot, isSnapshotDetail: showSnapshotDetails, currentTab: selectedTab })] }))] }), jsx(Alert, { alertType: 'warning', message: copyErrorMessage, open: alertError, onClose: () => {
                    setAlertOpen(false);
                }, autoHideDuration: 10000 }), jsx(Alert, { alertType: 'success', message: copySuccessMessage, open: alertSuccess, onClose: () => {
                    setSuccessOpen(false);
                }, autoHideDuration: 10000 })] }));
});
const MemoizedItemPanel = memo(ItemPanel);

export { ItemPanel, MemoizedItemPanel };
