import { jsxs, jsx } from 'react/jsx-runtime';
import { useTheme, Typography } from '@mui/material';
import { memo } from 'react';
import { Box } from '../../Box.js';
import '../components/PageSizeDropdown.js';
import '../components/PageSelector.js';
import '@fluentui/react-icons';
import { LinkWithArrow } from '../components/LinkWithArrow.js';
import '@mui/system';
import '../../../config.js';
import '../../../types/requestTypes.js';
import '../../../types/itemTypes.js';
import '../../../auth/config.js';
import '../../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../../../themes/light.theme.js';
import '../../../store/store.js';
import 'redux-persist/integration/react';
import '../../../store/slices/applicationSlice.js';
import '../../../store/slices/downloadFileSlice.js';
import '../../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../../constants/messages.js';
import '../../../services/requestApi.js';
import '../../../services/userApi.js';
import '../../../services/itemApi.js';
import '../../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import '../../item/utils.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../../store/thunks/downloadFileThunk.js';
import { useDisposalRequestExceptions } from '../../../hooks/useDisposalDetails.js';
import { useItemGridFilterCount } from '../../../hooks/useItemGridFilterCount.js';

const SummaryTemplate = ({ title, subTitle, adminMode, information, disposalId, isUnread, isDestructionActioned, disposalRequestStatus, onClick, onContextMenu, }) => {
    const theme = useTheme();
    const { data } = useDisposalRequestExceptions({ id: disposalId });
    const { all: items, deferredItems } = useItemGridFilterCount({
        disposalId: disposalId,
    });
    const hasExceptions = data.count > 0;
    const totalCount = (items?.count ?? 0) - (deferredItems?.count ?? 0);
    return (jsxs(Box, { direction: 'column', gap: 'xsmall', background: 'none', style: { marginLeft: '-0.75rem' }, onContextMenu: (e) => e.preventDefault(), children: [jsx(Box, { direction: 'row', background: 'none', alignItems: 'center', 
                // added styling for temporary removal of the Circle Icon below
                // TODO: remove style if we need to remove the comment for read / unread indicator
                style: { paddingLeft: '1.25rem' }, children: title && (jsx(LinkWithArrow, { title: title, onClick: onClick, onContextMenu: onContextMenu })) }), jsxs(Box, { background: 'none', style: { paddingLeft: '1.25rem' }, children: [jsx(Typography, { variant: 'body2', noWrap: true, children: subTitle }), jsx(Typography, { variant: 'body2', noWrap: true, sx: {
                            marginTop: subTitle ? '0.4rem' : undefined,
                        }, children: `Destroy ${totalCount} Item${totalCount === 1 ? '' : 's'}` }), isDestructionActioned && (jsx(Typography, { variant: 'body2', noWrap: true, sx: {
                            marginTop: subTitle ? '0.4rem' : undefined,
                        }, children: `Defer ${deferredItems?.count ?? 0} Item${deferredItems?.count === 1 ? '' : 's'}` })), jsx(Typography, { variant: 'body2', noWrap: true, sx: {
                            marginTop: '0.4rem',
                        }, children: information }), adminMode && (jsx(Typography, { variant: 'body3', noWrap: true, sx: {
                            marginTop: information ? '0.4rem' : undefined,
                            color: hasExceptions ? theme.palette.warning.main : undefined,
                        }, children: !!data.count &&
                            !isDestructionActioned &&
                            `${data.count} Approval exception${data.count > 1 ? 's' : ''}` }))] })] }));
};
const MemoizedSummaryTemplate = memo(SummaryTemplate);

export { MemoizedSummaryTemplate, SummaryTemplate };
