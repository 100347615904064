import { jsx } from 'react/jsx-runtime';
import styled from '@emotion/styled';
import { memo } from 'react';
import '../../../../config.js';
import '../../../../types/requestTypes.js';
import '../../../../types/itemTypes.js';
import '../../../../auth/config.js';
import '../../../../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '@mui/material';
import '../../../../themes/light.theme.js';
import '../../../../store/store.js';
import 'redux-persist/integration/react';
import '../../../../store/slices/applicationSlice.js';
import '../../../../store/slices/downloadFileSlice.js';
import '../../../../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../../../../constants/messages.js';
import '../../../../services/requestApi.js';
import '../../../../services/userApi.js';
import '../../../../services/itemApi.js';
import '../../../../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import { useIcon } from '../../../../hooks/useIcon.js';
import '../../utils.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../../../../store/thunks/downloadFileThunk.js';
import { Box } from '../../../Box.js';
import { Chip } from '../../../chip/Chip.js';
import '@fluentui/react-icons';
import '../../../chip/ItemManagementStatusChip.js';
import '../../../chip/PerspectiveClassChip.js';

const StyledImage = styled('img') ``;
const AttachedItems = memo(({ item }) => {
    const { iconDetails } = useIcon({
        imageId: item?._ImageId,
    });
    return (jsx(Box, { background: 'none', width: '100%', style: {
            maxWidth: 'fit-content',
        }, children: jsx(Chip, { icon: jsx(StyledImage, { src: `${process.env.REACT_APP_ENC_IMC_URL}${iconDetails?.Url ?? 'item.svg'}`, alt: iconDetails?.AltText, style: { height: '1.5rem' } }), title: item?.DisplayName, sx: { justifyContent: 'start', marginBottom: '0.5rem' } }) }));
});

export { AttachedItems };
