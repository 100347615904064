import { jsx } from 'react/jsx-runtime';
import { Tooltip, Typography as Typography$1 } from '@mui/material';
import 'react';
import '../config.js';
import '../types/requestTypes.js';
import '../types/itemTypes.js';
import '../auth/config.js';
import '../auth/AuthProvider.js';
import '@azure/msal-react';
import 'dayjs';
import 'react-redux';
import '@testing-library/react';
import '../themes/light.theme.js';
import '../store/store.js';
import 'redux-persist/integration/react';
import '../store/slices/applicationSlice.js';
import '../store/slices/downloadFileSlice.js';
import '../store/slices/itemModalSlice.js';
import '@pdftron/webviewer-video';
import '@pdftron/webviewer-audio';
import '../constants/messages.js';
import '../services/requestApi.js';
import '../services/userApi.js';
import '../services/itemApi.js';
import '../services/requestDetailsApi.js';
import '@pdftron/webviewer';
import 'lodash';
import './item/utils.js';
import { useTooltip } from '../hooks/useTooltip.js';
import 'react-router-dom';
import '@dnd-kit/core';
import '@dnd-kit/sortable';
import '../store/thunks/downloadFileThunk.js';

const Typography = ({ sx, children, WebkitLineClamp = 1, ...props }) => {
    const content = typeof children === 'string' ? children : '';
    const { dataRef, title } = useTooltip(content);
    return (jsx(Tooltip, { title: title, arrow: true, children: jsx(Typography$1, { ...props, ref: dataRef, sx: {
                width: 'auto',
                WebkitLineClamp,
                overflow: 'hidden',
                wordBreak: 'break-all',
                display: '-webkit-box',
                textOverflow: 'ellipsis',
                whiteSpace: 'break-spaces',
                WebkitBoxOrient: 'vertical',
                ...sx,
            }, children: children }) }));
};

export { Typography };
