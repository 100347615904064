import dayjs from 'dayjs';

//
const formatDate = (isoDateString, isDateTime = true) => {
    if (!isoDateString)
        return null;
    const dateObject = new Date(isoDateString);
    const twoDigitDay = padSingleDigitWithZero(dateObject.getDate());
    const twoDigitMonth = padSingleDigitWithZero(dateObject.getMonth() + 1);
    const fourDigitYear = dateObject.getFullYear();
    return !isDateTime ? `${twoDigitMonth}/${twoDigitDay}/${fourDigitYear}` : dateObject.toLocaleString().replace(',', '');
};
const formatCustomTypeDate = (isoDateString, isDateTime = true) => {
    const dateObject = new Date(isoDateString);
    const twoDigitDay = padSingleDigitWithZero(dateObject.getDate()).toLocaleString();
    const twoDigitMonth = padSingleDigitWithZero(dateObject.getMonth() + 1).toLocaleString();
    const fourDigitYear = dateObject.getFullYear();
    return isDateTime ? `${fourDigitYear}-${twoDigitMonth}-${twoDigitDay}T00:00:00` : `${fourDigitYear}-${twoDigitMonth}-${twoDigitDay}`;
};
const padSingleDigitWithZero = (number) => {
    return number < 10 ? `0${number}` : `${number}`;
};
const getFormattedDateTime = (dateTimeStr) => {
    if (!dateTimeStr)
        return null;
    return dayjs(dateTimeStr).format("MM/DD/YYYY HH:mm");
};
const formatDestructionDate = (isoDateString) => {
    const dateObject = new Date(isoDateString);
    const twoDigitDay = padSingleDigitWithZero(dateObject.getDate()).toLocaleString();
    const monthString = dateObject.toLocaleString('default', { month: 'short' }).toLocaleString();
    const fourDigitYear = dateObject.getFullYear();
    return `${twoDigitDay} ${monthString} ${fourDigitYear}`;
};
const areDatesEqual = (date1, date2, isDateTime = false) => {
    const parsedDate1 = new Date(date1);
    const parsedDate2 = new Date(date2);
    // Check if both dates are valid
    if (isNaN(parsedDate1?.getTime()) || isNaN(parsedDate2?.getTime())) {
        return false; // or throw an error if preferred
    }
    if (isDateTime) {
        // Compare both date and time
        return parsedDate1.getTime() === parsedDate2.getTime();
    }
    else {
        // Compare only the date (ignoring time)
        return (parsedDate1.getFullYear() === parsedDate2.getFullYear() &&
            parsedDate1.getMonth() === parsedDate2.getMonth() &&
            parsedDate1.getDate() === parsedDate2.getDate());
    }
};

export { areDatesEqual, formatCustomTypeDate, formatDate, formatDestructionDate, getFormattedDateTime, padSingleDigitWithZero };
